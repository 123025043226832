const config = {
    // customisation
    env: 'roleenergie',
    color: '#0286B4',
    rgbPDF: {
        r: 0.71,
        g: 0.48,
        b: 0.25
    },
    header: {
        backgroundPosition: '50% 60%',
        logo: {
            padding: 45,
            height: 70,
            width: 270
        },
        title: ''
    },
    name: 'Rôle Energies',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: '138 Rue Roland Garros',
	2: '34130 Mauguio'
    },
    mail: '',
    privacy: '',
    legal: 'https://www.role-energies.fr/mentions-legales/',
    modalType: 'achille', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: '',
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
    sendFormUrl: 'https://simulateur-energetique.role-energies.fr/sendPDF',
    fetchBill: 'https://simulateur-energetique.role-energies.fr/estimated-invoice',
    fetchEstimation: 'https://simulateur-energetique.role-energies.fr/pre-calculate',

    // external urls
    achilleUrl: 'https://role-energies.fhe-france.com',
};

export default config;
